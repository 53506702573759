import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-appli-dev',
  templateUrl: './appli-dev.component.html',
  styleUrls: ['./appli-dev.component.scss']
})
export class AppliDevComponent  implements OnInit {
    
    title!: number;
    description!: string;
    createdDate!: Date;
    snaps!: number;
    imageUrl!: string;
    


    ngOnInit() {
        this.title = 3655;
        this.description = 'Mon meilleur ami depuis tout petit !';
        this.createdDate = new Date();
        this.snaps = 6;
        this.imageUrl = 'https://cdn.pixabay.com/photo/2015/05/31/16/03/teddy-bear-792273_1280.jpg';
      }
      onAddSnap() {
        this.snaps++;
      }
}
